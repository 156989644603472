html, body {
  width:100%;
  height:100%; 
  background-image: url("https://microweather.today/img/mainBg.png");
  background-size: cover;
  font-family: 'S-CORE-DREAM';
  text-align: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
}

.w100 { width:100%; }
.w90 { width:90%; }
.w80 { width:80%; }
.w70 { width:70%; }
.w60 { width:60%; }
.w50 { width:50%; }
.w40 { width:40%; }
.w30 { width:30%; }
.w20 { width:20%; }
.w10 { width:10%; }


.h100 { height:100%; }

.w44 { width:44%; }
.w33 { width:33%; }
.w9 { width:9%; }

.fr {float:right;}
