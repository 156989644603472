@font-face {
  font-family: "S-Core-Dream";
  src: url("/public/font/scdream4.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "BebasNeue";
  src: url("/public/font/BebasNeue Regular.ttf") format("truetype");
  font-weight: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuBtn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width:79px;
  height:79px;
  padding: 0px 15px 0px 15px;
}

.menuBtn>span {
  position: absolute;
  font-size: 17px;
  font-weight: 700;
}

.footerAD {
  position: fixed;
  bottom: 0px;
  height:100px;
  width:100%;
  text-align: center;
  background-color: white;
}

.activeMenu {
  text-decoration: underline;
  padding: 5px;
  font-weight: 700;
}

.scehduleBox {
  padding:10px;
}

.scheduleList {
  border:0.5px solid black;
  padding:20px 10px 20px 10px;
  margin:10px;
  background-color: #F2F0F0;
  font-family: 'S-CORE-DREAM';
}

.planItem {
  /* border:1px solid black; */
  /* padding:5px; */
  margin:5px;
  text-align: left;
  line-height: 30px;
}

.weatherItem {
  border : 1px black solid;
  margin: 5px;
}

.weaherItemBox {
  overflow-y: auto;
}

.hasError {
  color : red;
}

.todayWeatherDiv {
  margin-top:90px;
  background: #97CAFC;
  backdrop-filter: blur(2px);
  width:300px;
  height:160px;
  display: inline-flex;
}

.sCoreDream {
  font-family: "S-Core-Dream";
}

.bebasNeue {
  font-family: "BebasNeue";
}

#root {
  height: 100%;
  width: 500px;
  position: relative;
  min-width: 300px;
  max-width: 100%;
}
